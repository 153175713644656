.stat {
  font-size: 2em;
}
.statButton {
  background-color: burlywood;
  border-color: bisque;
  font-weight: 700;
  visibility: hidden;
}
.statDown {
  float: left;
  margin-left: 1em;
  padding: 0.1em 0.5em 0.1em 1em;
  border-radius: 30px 0 0 20px;
}
.statDown:hover, .statUp:hover {
  background-color: peru;
  border-color: burlywood;
}
.statUp {
  float: right;
  margin-right: 1em;
  padding: 0.1em 1em 0.1em 0.5em; 
  border-radius: 0 30px 20px 0;
}
.baseStat {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.7em;
}
.statHr {
  border-color: darkred;
}
.editProfs {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.editProfBox {
  margin-top: 20%;
  border-radius: 10px;
  border-style: groove;
  border-color: darkred;
  background-color: #550000;
  padding: 1em 1em 0.5em 1em;
  width: 30vw;
  z-index: 5;
}

.prof-have {
  color: bisque
}
.prof-have:hover {
  color: peru;
  cursor: pointer;
}
.prof-need {
  color: saddlebrown;
}
.prof-need:hover {
  color: peru;
  cursor: pointer;
}
.playerStat {
  border: 2px solid darkred;
  background-color: #550000;
  padding: 2px;
  border-radius: 5px;
  margin: 1px;
  color: bisque;
}

.playerStat-inner {
  background-color: #550000;
  border-style: none;
  color: bisque;
  width: 100%;
}

.playerStat-inner:hover {
  color: peru;
}
.baseStat-inner {
  background-color: #550000;
  border-style: none;
  color: bisque;
  width: 100%;
}

.player-proficiencies {
  background-color: #550000;
  border-style: none;
  color: bisque;
  width: 100%;
  text-align: left;
}

.player-proficiencies:hover {
  color: peru;
}

.stat-profs {
  font-weight: 700;
  color: burlywood;
  font-size: 110%;
  display: block;
  text-align: center;
}

.player-proficiencies:hover .stat-profs {
  color: goldenrod;
}

.playerStat:hover {
  color: peru;
}

.baseStat-inner:hover .statButton {
  visibility: visible;
}
.proficiencies {
  padding: 2px;
  font-weight: 300;
  text-align: left;
}