
.playerDetails {
  display: flex;
  height: 90%;
}

.playerHeader {
  height: 8%;
}
.pdColumn {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 20%;
  height: 100%;
  overflow: hidden;
  overflow: scroll;
  scrollbar-width: none;
}

.pdSectionBreak {
  margin: 10px 0 0 0;
  width: 50%;
  align-self: center;
  border-color: darkred;
}

.playerInfoContainer {
  border: 2px solid darkred;
  background-color: darkred;
  border-radius: 10px;
  padding: 2px 0 2px 0;
  width: 100%;
  margin-top: 0.5em;
}

.profilePic {
  margin-top: 1vh;
  width: 14.5vw;
  height: 14.5vw;
}

.profilePic:hover {
  filter: sepia(100%);
}

.pdColumn h3, h1 {
  color: burlywood;
}

.info-string {
  font-size: 3vw;
}

.charName {
  font-size: 3.5vh;
}

.charName:hover, .playerName:hover {
  color: peru;
}

.playerName {
  font-size: 2.5vh;
}

.editField {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.editBox {
  margin-top: 20%;
  border-radius: 10px;
  border-style: groove;
  border-color: darkred;
  background-color: #550000;
  padding: 2vh;
  width: 30vw;
  height: 10em;
  z-index: 5;
}

.addBox {
  margin-top: 20%;
  border-radius: 10px;
  border-style: groove;
  border-color: darkred;
  background-color: #550000;
  padding: 2vh;
  width: 30vw;
  height: 20em;
  z-index: 5;
}

.info-header {
  font-weight: 700;
  font-size: 1.2em;
  color: burlywood;
}

.stat-header {
  font-weight: 700;
  font-size: 1.75em;
  color: burlywood;
}

.cancel-btn {
  font-size: 1em;
  background-color: darkred;
  color: #fff;
  width: 10vw;
  height: 3vw;
  max-width: 150px;
  max-height: 50px;
  outline: none;
  border: none;
  margin: 1em 0;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  text-align: center;
  letter-spacing: 1px;
}

.cancel-btn:hover {
  background-color: #770000;
  color: burlywood;
}

.accept-btn {
  font-size: 1em;
  background-color: darkgreen;
  color: #fff;
  width: 10vw;
  height: 3vw;  
  max-width: 150px;
  max-height: 50px;
  outline: none;
  border: none;
  margin: 1em 0;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  text-align: center;
  letter-spacing: 1px;
}

.accept-btn:hover {
  background-color: #005500;
  color: burlywood;
}


.playerInfoBox {
  display: inline-block;
  border: 2px solid darkred;
  background-color: #550000;
  padding: 1%;
  border-radius: 15px;
  margin: 0.5%;
  color: bisque;
  width: 44%;
}

.playerInfoBoxWide {
  display: inline-block;
  border: 2px solid darkred;
  background-color: #550000;
  padding: 1%;
  border-radius: 15px;
  margin: 1px;
  color: bisque;
  width: 94%;
}

.playerInfoBox:hover {
  color: peru;
}

.playerInfoBoxWide:hover {
  color: peru;
}

button span {
  pointer-events: none;
}

.edit-input {
  width: 200px;
  font-size: 2em;
  margin-top: -10px;
  text-align: center;
  background-color: #440000;
  border-color: darkred;
  color: peru;
}


.deletePlayer {
  background-color: #440000;
  border-color: darkred;
  position: absolute;
  top: 5px;
  left: 5px;
  color: darkred;
  height: 4vh;
  width: 6vw;
  font-weight: 700;
  border-radius: 5px;
  border-style: ridge;
}

.deletePlayer:hover {
  border-color: orangered;
  color: orangered;
}

.close-modal {
  background-color: darkgreen;
  border-color: green;
  position: absolute;
  top: 5px;
  right: 5px;
  color: khaki;
  height: 1.2em;
  width: 1.2em;
  font-weight: 700;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  font-size: 2em;
}

.close-modal:hover {
  border-color: khaki;
  color: lightgreen;
}

.sWidth {
  font-size: 2em;
}
@media screen and (max-width: 800px) {
  .sWidth {
    font-size: 4vw;
  }
}
@media screen and (min-width: 1200px) {
  .info-string {
    font-size: 1em;
  }
}

.levelPrompt {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
}
.levelBox {
  margin-top: 10%;
  border-radius: 10px;
  border-style: inset;
  border-color: darkred;
  background-color: chocolate;
  padding: 2vh;
  width: 30vw;
  height: 13vh;
  z-index: 3;
}
@import './player/stats.css';
@import './player//items.css';