.playerSheet {
  position: absolute;
  top: 2%;
  left: 2%;
  width: 95%;
  height: 90vh;
  background-color: rgb(90, 2, 2);
  color: antiquewhite;
  padding: 10px;
  border-radius: 25px;
  overflow: hidden;
}
.player-logout {
  background-color: darkgreen;
  border-color: green;
  position: absolute;
  top: 15px;
  right: 15px;
  color: khaki;
  height: 2em;
  width: 5vw;
  font-weight: 700;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  font-size: 1vw;
}

.player-logout:hover {
  border-color: khaki;
  color: lightgreen;
}

.playerSheet {
  font-size: 1vw;;
}
.playerSheet .player-proficiencies {
  font-size: 1vw;
}
.playerSheet .abilityList {
  font-size: 120%;
}
.playerSheet .stat {
  font-size: 2vw;
}

.playerSheet .info-header {
  font-size: 1.5vw;
}
.mobileFix {
  margin: 3vh 0 3vh 0;
}

.pItemModal {
  position: absolute;
  top: 15vh;
  left: 25vw;
  border-radius: 1px;
  border-style: inset;
  border-color: peru;
  background-color: chocolate;
  color: navajowhite;
  padding: 1vh 2vh 3vh 2vh;
  width: 50vw;
  height: 60vh;
  z-index: 3;
  overflow: hidden;
}

.pItemHeader {
  font-size: 3vw;
  font-family: 'Blinker', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  padding: 0;
}
.pItemDescription {
  padding: 1em 10% 1em 10%;
  text-align: left;
  white-space: pre-wrap;
  font-size: 1.25vw;
}