@import "css/hexgrid.css";
@import "css/login-register.css";
@import "css/topbar.css";
@import "css/bottombar.css";
@import "css/player.css";
@import "css/testUser.css";
@import "css/playersheet.css";

body {
  margin: 0;
  padding: 0;
  padding-top: 20px;
  font-family: 'Blinker', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(38, 58, 4);
  font-size: 16px;
}
a:link, a:visited { 
  color: bisque; 
  text-decoration: none;
}
a:hover  {
  color: peru;
}
code {
  font-family: 'Blinker', sans-serif;
}

.infoModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.infoModal-main {
  position: fixed;
  background: darkgreen;
  width: 400px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.playerInfo {
  position: absolute;
  top: 10%;
  left: 10%;
  bottom: 10%;
  right: 10%;
  width: 80%;
  height: 80%;
  background-color: rgb(90, 2, 2);
  color: antiquewhite;
  padding: 10px;
  border-radius: 5px;
}
::-webkit-scrollbar {
  width: 0px; 
  background: transparent;

}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.closeModal {
  opacity: 0;
}
.cm-top {
  position: absolute;
  top: 0;
  left: 0;
  height: 10%;
  width: 100%;
}
.cm-right {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 10%;
}
.cm-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 10%;
  width: 100%;
}
.cm-left {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 10%;
}

.savePrompt {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.promptBox {
  margin-top: 20%;
  border-radius: 10px;
  border-style: inset;
  border-color: darkred;
  background-color: chocolate;
  padding: 2vh;
  width: 30vw;
  height: 10vh;
  z-index: 3;
}
.deletePrompt {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.deleteBox {
  margin-top: 20%;
  border-radius: 10px;
  border-style: inset;
  border-color: darkred;
  background-color: chocolate;
  padding: 2vh;
  width: 30vw;
  height: 10vh;
  z-index: 3;
}

.lineBreaks {
  white-space: pre-line;
}
.info-text {
  width: 150px;
  border: none;
  padding: 1em;
  font-size: 1em;
  color: gold;
  margin: 12px;
  outline: none;
  -webkit-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  background-color: transparent;
  box-shadow: 0 0 0 30px #440a06 inset;
}
.info-submit {
  font-size: 1em;
  background-color: brown;
  color: #fff;
  width: 150px;
  height: 50px;
  outline: none;
  border: none;
  margin: 1em 0;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  text-align: center;
  letter-spacing: 1px;
}
.info-submit:hover {
  background-color: darkred;
}
button:focus {
  outline:0;
}
.small-screen {
  position: absolute;
  top: 10%;
  left: 10vw;
  width: 78vw;
  padding: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: chocolate;
}

@import "css/smallScreen.css";
