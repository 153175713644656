.login-row {
  background-color: #440a06;
  width: 420px;
  height: 310px;
  padding: 20px 10px 20px 10px;
  margin: auto;
  border-top: 7px solid darkred;
  border-bottom: 7px solid darkred;
}

.register-row {
  background-color: #440a06;
  width: 420px;
  height: 490px;
  padding: 20px 10px 20px 10px;
  margin: auto;
  border-top: 7px solid darkred;
  border-bottom: 7px solid darkred;
}

.form-p {
  padding: 5px;
}

.login-text {
  display: block;
  width: 85%;
  border: none;
  border-bottom: solid 1px #fff;
  padding: 1em;
  font-size: 1em;
  color: gold;
  margin: 35px 0 12px;
  margin-left: 8px;
  outline: none;
  -webkit-transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  background-color: transparent;
  box-shadow: 0 0 0 30px #440a06 inset;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #440a06 inset !important;
  -webkit-text-fill-color: gold !important;
}

::-webkit-input-placeholder {
  color: darkgoldenrod;
}

.login-submit {
  font-size: 1em;
  background-color: brown;
  color: #fff;
  width: 100%;
  height: 50px;
  outline: none;
  border: none;
  margin: 1em 0;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  text-align: center;
  letter-spacing: 1px;
  cursor: pointer;  

}

.login-submit:hover {
  background-color: darkred;
}

.form-p {
  margin-top: 5px;
  font-size: 1.1em;
  color: #fff;
  letter-spacing: 3px;
  text-align: center;
}

.form-label {
  float: left;
}

.login-signup {
  height: 50px;
  width: 100%;
  display: flex;
}

.selected-section {
  font-size: 1em;
  color: darkgoldenrod;
  outline: none;
  border: none;
  text-align: center;
  align-self: center;
  letter-spacing: 1px;
  font-weight: 700;
}

.unselected-section {
  font-size: 1em;
  color: #fff;
  outline: none;
  border: none;
  letter-spacing: 1px;
}

.login-signup-unselected-btn:hover {
  background-color: darkred;
}

.login-signup-selected-btn {
  background-color: darkred;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-signup-unselected-btn {
  background-color: brown;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  text-align: center;
  cursor: pointer;
}

.login-signup-title {
  color: goldenrod;
  font-size: 4em;
}

.disclaimer, span {
  color: gray;
}

.example-btn {
  font-size: 1em;
  background-color: rgb(168, 76, 10);
  border-color: rgb(38, 58, 4);
  color: navajowhite;
  width: 350px;
  height: 50px;
  outline: none;
  border-style: outset;
  margin: 1em 0;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  text-align: center;
  letter-spacing: 2px;
  border-radius: 5px;
  cursor: pointer;
}

.example-btn:hover {
  background-color: rgb(209, 99, 21);
}

.player-login-btn {
  font-size: 1em;
  background-color: darkred;
  color: burlywood;
  width: 440px;
  height: 50px;
  outline: none;
  border: none;
  margin: 0;
  -webkit-transition: 0.5s all;
  -ms-transition: 0.5s all;
  transition: 0.5s all;
  text-align: center;
  letter-spacing: 1px;
  cursor: pointer;

}

.player-login-btn:hover {
  background-color: rgb(168, 76, 10);
}