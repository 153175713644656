* {
  margin: 0;
  padding: 0;
}

#hexGrid {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 90%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 60px;
  overflow: hidden;
  font-size: 15px;
  list-style-type: none;
}

.hex {
  position: relative;
  visibility: hidden;
  outline: 1px solid transparent;
  /* fix for jagged edges in FF on hover transition */
}

.hex::after {
  content: '';
  display: block;
  padding-bottom: 86.602%;
  /* =  100 / tan(60) * 1.5 */
}

.hexIn {
  position: absolute;
  width: 96%;
  padding-bottom: 110.851%;
  /* =  width / sin(60) */
  margin: 0 2%;
  overflow: hidden;
  visibility: hidden;
  outline: 1px solid transparent;
  /* fix for jagged edges in FF on hover transition */
  -webkit-transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
  transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
}

.hexIn * {
  position: absolute;
  visibility: visible;
  outline: 1px solid transparent;
  /* fix for jagged edges in FF on hover transition */
}

.hexLink {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  color: gold;
  overflow: hidden;
  -webkit-transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
  transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
}

/*** HEX CONTENT **********************************************************************/

.hex img {
  left: -100%;
  right: -100%;
  width: auto;
  height: 100%;
  margin: 0 auto;
  -webkit-transform: rotate3d(0, 0, 0, 0deg);
  transform: rotate3d(0, 0, 0, 0deg);
}

.hex h1, .hex p {
  width: 100%;
  padding: 5%;
  box-sizing: border-box;
  background-color: rgba(165, 41, 19, 0.8);
  font-weight: 700;
  transition: opacity .3s ease-out, -webkit-transform .2s ease-out;
  transition: transform .2s ease-out, opacity .3s ease-out;
  transition: transform .2s ease-out, opacity .3s ease-out, -webkit-transform .2s ease-out;
}

.hex h1 {
  bottom: 50%;
  padding-top: 50%;
  font-size: 1.5em;
  z-index: 1;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.hex h1::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 35%;
  width: 30%;
  text-align: center;
  border-bottom: 1px solid gold;
}

.hex p {
  top: 50%;
  padding-bottom: 50%;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  color: goldenrod;
}

/* HOVER EFFECT */

.hexLink:hover h1, .hexLink:focus h1, .hexLink:hover p, .hexLink:focus p {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/*** HEXAGON SIZING AND EVEN ROW INDENTATION *****************************************************************/

@media (min-width:1201px) {
  /* <- 5-4  hexagons per row */
  #hexGrid {
    padding-bottom: 4.4%
  }
  .hex {
    width: 20%;
    /* = 100 / 5 */
  }
  .hex:nth-child(9n+6) {
    /* first hexagon of even rows */
    margin-left: 10%;
    /* = width of .hex / 2  to indent even rows */
  }
}

@media (max-width: 1200px) and (min-width:901px) {
  /* <- 4-3  hexagons per row */
  #hexGrid {
    padding-bottom: 5.5%;
    font-size: 80%
  }
  .hex {
    width: 25%;
    /* = 100 / 4 */
  }
  .hex:nth-child(7n+5) {
    /* first hexagon of even rows */
    margin-left: 12.5%;
    /* = width of .hex / 2  to indent even rows */
  }
}

@media (max-width: 900px) and (min-width:601px) {
  /* <- 3-2  hexagons per row */
  #hexGrid {
    padding-bottom: 7.4%;
    font-size: 80%;
  }
  .hex {
    width: 33.333%;
    /* = 100 / 3 */
  }
  .hex:nth-child(5n+4) {
    /* first hexagon of even rows */
    margin-left: 16.666%;
    /* = width of .hex / 2  to indent even rows */
  }
}

@media (max-width: 600px) {
  /* <- 2-1  hexagons per row */
  #hexGrid {
    padding-bottom: 11.2%
  }
  .hex {
    width: 50%;
    /* = 100 / 3 */
  }
  .hex:nth-child(3n+3) {
    /* first hexagon of even rows */
    margin-left: 25%;
    /* = width of .hex / 2  to indent even rows */
  }
}

@media (max-width: 400px) {
  #hexGrid {
    font-size: 13px;
  }
}
.login-row {
  background-color: #440a06;
  width: 420px;
  height: 310px;
  padding: 20px 10px 20px 10px;
  margin: auto;
  border-top: 7px solid darkred;
  border-bottom: 7px solid darkred;
}

.register-row {
  background-color: #440a06;
  width: 420px;
  height: 490px;
  padding: 20px 10px 20px 10px;
  margin: auto;
  border-top: 7px solid darkred;
  border-bottom: 7px solid darkred;
}

.form-p {
  padding: 5px;
}

.login-text {
  display: block;
  width: 85%;
  border: none;
  border-bottom: solid 1px #fff;
  padding: 1em;
  font-size: 1em;
  color: gold;
  margin: 35px 0 12px;
  margin-left: 8px;
  outline: none;
  transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  background-color: transparent;
  box-shadow: 0 0 0 30px #440a06 inset;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #440a06 inset !important;
  -webkit-text-fill-color: gold !important;
}

::-webkit-input-placeholder {
  color: darkgoldenrod;
}

.login-submit {
  font-size: 1em;
  background-color: brown;
  color: #fff;
  width: 100%;
  height: 50px;
  outline: none;
  border: none;
  margin: 1em 0;
  transition: 0.5s all;
  text-align: center;
  letter-spacing: 1px;
  cursor: pointer;  

}

.login-submit:hover {
  background-color: darkred;
}

.form-p {
  margin-top: 5px;
  font-size: 1.1em;
  color: #fff;
  letter-spacing: 3px;
  text-align: center;
}

.form-label {
  float: left;
}

.login-signup {
  height: 50px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
}

.selected-section {
  font-size: 1em;
  color: darkgoldenrod;
  outline: none;
  border: none;
  text-align: center;
  -webkit-align-self: center;
          align-self: center;
  letter-spacing: 1px;
  font-weight: 700;
}

.unselected-section {
  font-size: 1em;
  color: #fff;
  outline: none;
  border: none;
  letter-spacing: 1px;
}

.login-signup-unselected-btn:hover {
  background-color: darkred;
}

.login-signup-selected-btn {
  background-color: darkred;
  width: 50%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.login-signup-unselected-btn {
  background-color: brown;
  width: 50%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  transition: 0.5s all;
  text-align: center;
  cursor: pointer;
}

.login-signup-title {
  color: goldenrod;
  font-size: 4em;
}

.disclaimer, span {
  color: gray;
}

.example-btn {
  font-size: 1em;
  background-color: rgb(168, 76, 10);
  border-color: rgb(38, 58, 4);
  color: navajowhite;
  width: 350px;
  height: 50px;
  outline: none;
  border-style: outset;
  margin: 1em 0;
  transition: 0.5s all;
  text-align: center;
  letter-spacing: 2px;
  border-radius: 5px;
  cursor: pointer;
}

.example-btn:hover {
  background-color: rgb(209, 99, 21);
}

.player-login-btn {
  font-size: 1em;
  background-color: darkred;
  color: burlywood;
  width: 440px;
  height: 50px;
  outline: none;
  border: none;
  margin: 0;
  transition: 0.5s all;
  text-align: center;
  letter-spacing: 1px;
  cursor: pointer;

}

.player-login-btn:hover {
  background-color: rgb(168, 76, 10);
}
.topbar {
  width: 100%;
  height: 50px;
  background-color: darkred;
  position: fixed;
  z-index: 2;
  top: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.topbar-section {
  padding: 0 20px 0 20px;
}
.logout-btn {
  border-color: brown;
  border-style: groove;
  border-radius: 5px;
  height: 40px;
  background-color: chocolate;
  padding: 5px 10px 5px 10px;
  font-weight: 700;
  color: black;
  border-bottom-right-radius: 20px;
}
.logout-btn:hover {
  background-color: goldenrod;
}
.settings-btn {
  border-color: brown;
  border-style: groove;
  border-radius: 5px;
  height: 40px;
  background-color: chocolate;
  padding: 5px 10px 5px 10px;
  font-weight: 700;
  color: black;
  border-bottom-left-radius: 20px;
}
.settings-btn:hover {
  background-color: goldenrod;
}
.topbar-username {
  font-size: 40px;
  color: black;
  padding-right: 10px;
}
.topbar-gamename {
  font-size: 34px;
  color: black;
}
.bottombar {
  width: 100%;
  height: 50px;
  background-color: darkred;
  position: fixed;
  bottom: 0;
  z-index: 2;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.bottombar-main {
  padding: 0 20px 0 20px;
  width: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;

}
.bottombar-right {
  padding: 0 20px 0 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}
.newGame-btn {
  border-color: brown;
  border-style: groove;
  border-radius: 5px;
  height: 40px;
  background-color: chocolate;
  padding: 5px 10px 5px 10px;
  font-weight: 700;
  color: #005500;
  border-top-right-radius: 20px;
}
.newGame-btn:hover {
  background-color: goldenrod;
  color: darkgreen;
}
.current-game {
  background-color: goldenrod;
  padding: 10px;
  border-radius: 10px;
  border-style: solid;
  border-color: darkgoldenrod;
  color: darkgreen;
  font-weight: 700;
  margin-left: 10px;
}
.other-game {
  background-color: darkgoldenrod;
  padding: 10px;
  border-radius: 10px;
  border-style: solid;
  border-color: goldenrod;
  color: darkred;
  font-weight: 700;
  margin-left: 10px;

}
.delete-btn {
  border-color: brown;
  border-style: groove;
  border-radius: 5px;
  height: 40px;
  background-color: chocolate;
  padding: 5px 10px 5px 10px;
  font-weight: 700;
  color: darkred;
  border-top-left-radius: 20px;
}

.delete-btn:hover {
  background-color: goldenrod;
}
.stat {
  font-size: 2em;
}
.statButton {
  background-color: burlywood;
  border-color: bisque;
  font-weight: 700;
  visibility: hidden;
}
.statDown {
  float: left;
  margin-left: 1em;
  padding: 0.1em 0.5em 0.1em 1em;
  border-radius: 30px 0 0 20px;
}
.statDown:hover, .statUp:hover {
  background-color: peru;
  border-color: burlywood;
}
.statUp {
  float: right;
  margin-right: 1em;
  padding: 0.1em 1em 0.1em 0.5em; 
  border-radius: 0 30px 20px 0;
}
.baseStat {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.7em;
}
.statHr {
  border-color: darkred;
}
.editProfs {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.editProfBox {
  margin-top: 20%;
  border-radius: 10px;
  border-style: groove;
  border-color: darkred;
  background-color: #550000;
  padding: 1em 1em 0.5em 1em;
  width: 30vw;
  z-index: 5;
}

.prof-have {
  color: bisque
}
.prof-have:hover {
  color: peru;
  cursor: pointer;
}
.prof-need {
  color: saddlebrown;
}
.prof-need:hover {
  color: peru;
  cursor: pointer;
}
.playerStat {
  border: 2px solid darkred;
  background-color: #550000;
  padding: 2px;
  border-radius: 5px;
  margin: 1px;
  color: bisque;
}

.playerStat-inner {
  background-color: #550000;
  border-style: none;
  color: bisque;
  width: 100%;
}

.playerStat-inner:hover {
  color: peru;
}
.baseStat-inner {
  background-color: #550000;
  border-style: none;
  color: bisque;
  width: 100%;
}

.player-proficiencies {
  background-color: #550000;
  border-style: none;
  color: bisque;
  width: 100%;
  text-align: left;
}

.player-proficiencies:hover {
  color: peru;
}

.stat-profs {
  font-weight: 700;
  color: burlywood;
  font-size: 110%;
  display: block;
  text-align: center;
}

.player-proficiencies:hover .stat-profs {
  color: goldenrod;
}

.playerStat:hover {
  color: peru;
}

.baseStat-inner:hover .statButton {
  visibility: visible;
}
.proficiencies {
  padding: 2px;
  font-weight: 300;
  text-align: left;
}
.abilityList a h3 {
  margin-top: 0.5em;
  color: bisque;
}
.abilityList a h3:hover {
  color: peru;
}
.addSpell {
  margin-top: 11.5%;
  border-radius: 10px;
  border-style: groove;
  border-color: darkred;
  background-color: #550000;
  padding: 2vh;
  width: 30vw;
  height: 33em;
  z-index: 5;
}
.spellCheckBoxfalse {
  margin: 2em;
color: saddlebrown;
}
.spellCheckBoxfalse:hover {
 cursor: pointer; 
}
.spellCheckBoxtrue {
  margin: 2em;
  color: navajowhite;
  font-weight: 700;
}
.spellCheckBoxtrue:hover {
 cursor: pointer; 
}
.addItem {
  margin-top: 10px;
  border-color: darkred;
  background-color: #830000;
  color: bisque;
  width: 60%;
  -webkit-align-self: center;
          align-self: center;
  padding: 5px;
  margin-bottom: 10px;
  font-weight: 700;
}

.addItem:hover {
  background-color: #770000;
}
.addText {
  background-color: #440000;
  padding: 5px;
  border-color: darkred;
  resize: none;
  color: goldenrod;
  min-width: 177px;
}
.spellText {
  background-color: #440000;
  padding: 5px;
  margin: 2px 5px 2px 5px;
  border-color: darkred;
  resize: none;
  color: goldenrod;
  min-width: 10px;
  width: 100px;
}
.newSpell {
  text-align: center;
}

.addItemP {
  color: burlywood;
}
.editItem {
  background-color: darkgreen;
  border-color: peru;
  position: absolute;
  top: 5px;
  right: 5px;
  color: #004400;
  height: 4vh;
  width: 6vw;
  font-weight: 700;
  border-radius: 5px;
  border-style: groove;
}

.editItem:hover {
  border-color: darkslategrey;
  color: goldenrod;
}
.deleteItem {
  background-color: darkred;
  border-color: peru;
  position: absolute;
  top: 5px;
  left: 5px;
  color: #440000;
  height: 4vh;
  width: 6vw;
  font-weight: 700;
  border-radius: 5px;
  border-style: groove;
}
.deleteItem:hover {
  border-color: darkred;
  color: goldenrod;
}

.search-spells {
  margin-left: 0.5em;
  background-color: peru;
  border-color: darkgoldenrod;
  padding: 3px;
  border-radius: 2px;
  font-weight: 700;
  color: black;
}

.itemModal {
  position: absolute;
  top: 15vh;
  left: 30vw;
  border-radius: 1px;
  border-style: inset;
  border-color: peru;
  background-color: chocolate;
  color: navajowhite;
  padding: 1vh 2vh 3vh 2vh;
  width: 40vw;
  height: 40vh;
  z-index: 3;
  overflow: hidden;
}
.itemModalInner {
  background-color: chocolate;
  color: navajowhite;
  width: 100%;
  height: 92%;
  margin-top: 1vh;
  z-index: 3;
  overflow: scroll;
}
.im-top {
  position: absolute;
  top: 0;
  left: 0;
  height: 15vh;
  width: 100vw;
  z-index: 5;
  opacity: 0;
}
.im-right {
  position: absolute;
  top: 15vh;
  right: 0;
  height: 45vh;
  z-index: 5;
  opacity: 0;
  width: 27.5vw;
}
.im-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 5;
  height: 40vh;
  opacity: 0;
  width: 100%;
}
.im-left {
  position: absolute;
  top: 15vh;
  left: 0;
  height: 45vh;
  width: 30vw;
  z-index: 5;
  opacity: 0;
}
.itemHr {
  border-color: peru;
  margin: 1vh 8vw 0 8vw;
}
.itemHeader {
  font-size: 200%;
  font-family: 'Blinker', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  padding: 0;
}
.itemDescription {
  padding: 1em 10% 1em 10%;
  text-align: left;
  white-space: pre-wrap;
}
.editDescription {
  background-color: saddlebrown;
  padding: 1em;
  border-color: peru;
  resize: none;
  color: navajowhite;
  min-width: 177px;
  width: 80%;
  height: 90%;
}
.editSpellText {
  background-color: saddlebrown;
  padding: 5px;
  margin: 2px 5px 2px 5px;
  border-color: peru;
  resize: none;
  color: navajowhite;
  min-width: 10px;
  width: 100px;
}

.playerDetails {
  display: -webkit-flex;
  display: flex;
  height: 90%;
}

.playerHeader {
  height: 8%;
}
.pdColumn {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 10px;
  width: 20%;
  height: 100%;
  overflow: hidden;
  overflow: scroll;
  scrollbar-width: none;
}

.pdSectionBreak {
  margin: 10px 0 0 0;
  width: 50%;
  -webkit-align-self: center;
          align-self: center;
  border-color: darkred;
}

.playerInfoContainer {
  border: 2px solid darkred;
  background-color: darkred;
  border-radius: 10px;
  padding: 2px 0 2px 0;
  width: 100%;
  margin-top: 0.5em;
}

.profilePic {
  margin-top: 1vh;
  width: 14.5vw;
  height: 14.5vw;
}

.profilePic:hover {
  -webkit-filter: sepia(100%);
          filter: sepia(100%);
}

.pdColumn h3, h1 {
  color: burlywood;
}

.info-string {
  font-size: 3vw;
}

.charName {
  font-size: 3.5vh;
}

.charName:hover, .playerName:hover {
  color: peru;
}

.playerName {
  font-size: 2.5vh;
}

.editField {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.editBox {
  margin-top: 20%;
  border-radius: 10px;
  border-style: groove;
  border-color: darkred;
  background-color: #550000;
  padding: 2vh;
  width: 30vw;
  height: 10em;
  z-index: 5;
}

.addBox {
  margin-top: 20%;
  border-radius: 10px;
  border-style: groove;
  border-color: darkred;
  background-color: #550000;
  padding: 2vh;
  width: 30vw;
  height: 20em;
  z-index: 5;
}

.info-header {
  font-weight: 700;
  font-size: 1.2em;
  color: burlywood;
}

.stat-header {
  font-weight: 700;
  font-size: 1.75em;
  color: burlywood;
}

.cancel-btn {
  font-size: 1em;
  background-color: darkred;
  color: #fff;
  width: 10vw;
  height: 3vw;
  max-width: 150px;
  max-height: 50px;
  outline: none;
  border: none;
  margin: 1em 0;
  transition: 0.5s all;
  text-align: center;
  letter-spacing: 1px;
}

.cancel-btn:hover {
  background-color: #770000;
  color: burlywood;
}

.accept-btn {
  font-size: 1em;
  background-color: darkgreen;
  color: #fff;
  width: 10vw;
  height: 3vw;  
  max-width: 150px;
  max-height: 50px;
  outline: none;
  border: none;
  margin: 1em 0;
  transition: 0.5s all;
  text-align: center;
  letter-spacing: 1px;
}

.accept-btn:hover {
  background-color: #005500;
  color: burlywood;
}


.playerInfoBox {
  display: inline-block;
  border: 2px solid darkred;
  background-color: #550000;
  padding: 1%;
  border-radius: 15px;
  margin: 0.5%;
  color: bisque;
  width: 44%;
}

.playerInfoBoxWide {
  display: inline-block;
  border: 2px solid darkred;
  background-color: #550000;
  padding: 1%;
  border-radius: 15px;
  margin: 1px;
  color: bisque;
  width: 94%;
}

.playerInfoBox:hover {
  color: peru;
}

.playerInfoBoxWide:hover {
  color: peru;
}

button span {
  pointer-events: none;
}

.edit-input {
  width: 200px;
  font-size: 2em;
  margin-top: -10px;
  text-align: center;
  background-color: #440000;
  border-color: darkred;
  color: peru;
}


.deletePlayer {
  background-color: #440000;
  border-color: darkred;
  position: absolute;
  top: 5px;
  left: 5px;
  color: darkred;
  height: 4vh;
  width: 6vw;
  font-weight: 700;
  border-radius: 5px;
  border-style: ridge;
}

.deletePlayer:hover {
  border-color: orangered;
  color: orangered;
}

.close-modal {
  background-color: darkgreen;
  border-color: green;
  position: absolute;
  top: 5px;
  right: 5px;
  color: khaki;
  height: 1.2em;
  width: 1.2em;
  font-weight: 700;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  font-size: 2em;
}

.close-modal:hover {
  border-color: khaki;
  color: lightgreen;
}

.sWidth {
  font-size: 2em;
}
@media screen and (max-width: 800px) {
  .sWidth {
    font-size: 4vw;
  }
}
@media screen and (min-width: 1200px) {
  .info-string {
    font-size: 1em;
  }
}

.levelPrompt {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.levelBox {
  margin-top: 10%;
  border-radius: 10px;
  border-style: inset;
  border-color: darkred;
  background-color: chocolate;
  padding: 2vh;
  width: 30vw;
  height: 13vh;
  z-index: 3;
}
.testUserModal {
  position: absolute;
  top: 30vh;
  left: 30vw;
  border-radius: 1px;
  border-style: inset;
  border-color: brown;
  background-color: darkred;
  color: navajowhite;
  padding: 1vh 2vh 0 2vh;
  width: 40vw;
  height: auto;
  height: initial;
  z-index: 3;
  overflow: scroll;
}
.testUser-btn {
  font-size: 1em;
  background-color: darkgreen;
  border-color: rgb(38, 58, 4);
  color: navajowhite;
  width: 10vw;
  height: 3vw;  
  max-width: 150px;
  max-height: 50px;
  outline: none;
  border-style: outset;
  margin: 1em 0;
  transition: 0.5s all;
  text-align: center;
  letter-spacing: 1px;
}

.testUser-btn:hover {
  background-color: #005500;
  color: burlywood;
}

.tutorial-container {
  position: absolute;
  top: 10%;
  left: 10%;
  bottom: 10%;
  right: 10%;
  width: 80%;
  height: 80%;
  z-index: 4;
  padding: 10px;
  border-radius: 5px;
}

.tut-active {
  background-color: rgba(200, 0, 0, 0.2);
  border-radius: 2em; 
}
.continue-btn {
  font-size: 1em;
  background-color: darkgreen;
  color: #fff;
  width: 5vw;
  height: 3vh;  
  max-width: 150px;
  max-height: 50px;
  outline: none;
  border: none;
  margin: 1em 0 0 0;
  transition: 0.5s all;
  text-align: center;
  letter-spacing: 1px;
  border-radius: 2px;
}

.continue-btn:hover {
  background-color: #005500;
  color: burlywood;
}
.step1 {
  position: absolute;
  left: 15vw;
  top: 15vh;
  width: 20vw;
  background-color: antiquewhite;
  border-radius: 50% 20px 20px 50%;
  color: darkslateblue;
  padding: 1em 1em 1em 4em;
  box-shadow: 5px 10px rgba(0, 0, 0, 0.5);

}
.step2 {
  position: absolute;
  left: 30vw;
  top: 25vh;
  width: 20vw;
  background-color: antiquewhite;
  border-radius: 10%;
  color: darkslateblue;
  padding: 1em;
  box-shadow: 5px 10px rgba(0, 0, 0, 0.5);
}
.step3 {
  position: absolute;
  left: 42vw;
  top: 35vh;
  width: 20vw;
  background-color: antiquewhite;
  border-radius: 20px 50% 50% 20px;
  color: darkslateblue;
  padding: 1em 4em 1em 1em;
  box-shadow: 5px 10px rgba(0, 0, 0, 0.5);
}
.step4 {
  position: absolute;
  left: 45vw;
  top: 45vh;
  width: 35vw;
  border-radius: 50% 20px 20px 50%;
  background-color: antiquewhite;
  color: darkslateblue;
  padding: 1em 1em 1em 4em;
  box-shadow: 5px 10px rgba(0, 0, 0, 0.5);
}
.step5  {
  position: absolute;
  left: 35vw;
  top: 25vh;
  width: 30vw;
  background-color: antiquewhite;
  border-radius: 20px;
  color: darkslateblue;
  padding: 1em;
  z-index: 5;
  box-shadow: 5px 10px rgba(0, 0, 0, 0.5);
}
.testUserModal .itemDescription {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.playerSheet {
  position: absolute;
  top: 2%;
  left: 2%;
  width: 95%;
  height: 90vh;
  background-color: rgb(90, 2, 2);
  color: antiquewhite;
  padding: 10px;
  border-radius: 25px;
  overflow: hidden;
}
.player-logout {
  background-color: darkgreen;
  border-color: green;
  position: absolute;
  top: 15px;
  right: 15px;
  color: khaki;
  height: 2em;
  width: 5vw;
  font-weight: 700;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  font-size: 1vw;
}

.player-logout:hover {
  border-color: khaki;
  color: lightgreen;
}

.playerSheet {
  font-size: 1vw;;
}
.playerSheet .player-proficiencies {
  font-size: 1vw;
}
.playerSheet .abilityList {
  font-size: 120%;
}
.playerSheet .stat {
  font-size: 2vw;
}

.playerSheet .info-header {
  font-size: 1.5vw;
}
.mobileFix {
  margin: 3vh 0 3vh 0;
}

.pItemModal {
  position: absolute;
  top: 15vh;
  left: 25vw;
  border-radius: 1px;
  border-style: inset;
  border-color: peru;
  background-color: chocolate;
  color: navajowhite;
  padding: 1vh 2vh 3vh 2vh;
  width: 50vw;
  height: 60vh;
  z-index: 3;
  overflow: hidden;
}

.pItemHeader {
  font-size: 3vw;
  font-family: 'Blinker', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  padding: 0;
}
.pItemDescription {
  padding: 1em 10% 1em 10%;
  text-align: left;
  white-space: pre-wrap;
  font-size: 1.25vw;
}
/* For screens smaller than 1201px wide */

@media screen and (max-width: 1200px) {

  .info-string {
    font-size: 2.5vw;
  }
  .stat-header {
    font-size: 2.2vw;
  }
  .baseStat {
    font-size: 2vw;
  }
  .statButton {
    margin: 0.3em 0 0 0;
    padding: 0;
    border-radius: 50%;
    width: 1.2em;
  }
  .info-header, .stat-profs, .profList {
    font-size: 1em;
  }
  .pdColumn h1, .pdColumn h3 {
    font-size: 1.1em;
  }
  .stat {
    font-size: 1.7em;
  }
  .deletePlayer {
    font-size: 1.2vw;
  }

}
body {
  margin: 0;
  padding: 0;
  padding-top: 20px;
  font-family: 'Blinker', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(38, 58, 4);
  font-size: 16px;
}
a:link, a:visited { 
  color: bisque; 
  text-decoration: none;
}
a:hover  {
  color: peru;
}
code {
  font-family: 'Blinker', sans-serif;
}

.infoModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.infoModal-main {
  position: fixed;
  background: darkgreen;
  width: 400px;
  height: auto;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 5px;
}

.playerInfo {
  position: absolute;
  top: 10%;
  left: 10%;
  bottom: 10%;
  right: 10%;
  width: 80%;
  height: 80%;
  background-color: rgb(90, 2, 2);
  color: antiquewhite;
  padding: 10px;
  border-radius: 5px;
}
::-webkit-scrollbar {
  width: 0px; 
  background: transparent;

}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.closeModal {
  opacity: 0;
}
.cm-top {
  position: absolute;
  top: 0;
  left: 0;
  height: 10%;
  width: 100%;
}
.cm-right {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 10%;
}
.cm-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 10%;
  width: 100%;
}
.cm-left {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 10%;
}

.savePrompt {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.promptBox {
  margin-top: 20%;
  border-radius: 10px;
  border-style: inset;
  border-color: darkred;
  background-color: chocolate;
  padding: 2vh;
  width: 30vw;
  height: 10vh;
  z-index: 3;
}
.deletePrompt {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.deleteBox {
  margin-top: 20%;
  border-radius: 10px;
  border-style: inset;
  border-color: darkred;
  background-color: chocolate;
  padding: 2vh;
  width: 30vw;
  height: 10vh;
  z-index: 3;
}

.lineBreaks {
  white-space: pre-line;
}
.info-text {
  width: 150px;
  border: none;
  padding: 1em;
  font-size: 1em;
  color: gold;
  margin: 12px;
  outline: none;
  transition: all 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
  background-color: transparent;
  box-shadow: 0 0 0 30px #440a06 inset;
}
.info-submit {
  font-size: 1em;
  background-color: brown;
  color: #fff;
  width: 150px;
  height: 50px;
  outline: none;
  border: none;
  margin: 1em 0;
  transition: 0.5s all;
  text-align: center;
  letter-spacing: 1px;
}
.info-submit:hover {
  background-color: darkred;
}
button:focus {
  outline:0;
}
.small-screen {
  position: absolute;
  top: 10%;
  left: 10vw;
  width: 78vw;
  padding: 1vw;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: chocolate;
}

.App {
  text-align: center;
}

