/* For screens smaller than 1201px wide */

@media screen and (max-width: 1200px) {

  .info-string {
    font-size: 2.5vw;
  }
  .stat-header {
    font-size: 2.2vw;
  }
  .baseStat {
    font-size: 2vw;
  }
  .statButton {
    margin: 0.3em 0 0 0;
    padding: 0;
    border-radius: 50%;
    width: 1.2em;
  }
  .info-header, .stat-profs, .profList {
    font-size: 1em;
  }
  .pdColumn h1, .pdColumn h3 {
    font-size: 1.1em;
  }
  .stat {
    font-size: 1.7em;
  }
  .deletePlayer {
    font-size: 1.2vw;
  }

}